import { WebsiteContent } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import {
  ArrowRightIcon,
  ArrowUpRightIcon,
  ChainLink2Icon,
} from '@fingertip/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { prependUrl } from '@/lib/utils/utils'

import { BlockAssetInner } from '../shared/block-asset-inner'
import { BlockProps } from '../shared/block-switch'

export const WebsiteBlock = ({
  siteSlug,
  domain,
  block,
  handleClick,
  w,
  h,
  y,
  scaleFactor,
}: BlockProps) => {
  const { t } = useTranslation()
  const content = block?.content?.content?.value as WebsiteContent

  let websiteUrls = content?.websiteUrls

  if (!websiteUrls || websiteUrls?.length === 0) {
    return null
  }

  const openInNewTab = !!websiteUrls?.[0]?.openInNewTab

  return (
    <a
      onClick={() => handleClick?.(block, content?.title)}
      href={prependUrl(websiteUrls?.[0]?.websiteUrl, { siteSlug, domain })}
      target={openInNewTab ? '_blank' : undefined}
      className="relative flex size-full"
    >
      <BlockAssetInner
        media={content?.media}
        title={content?.title}
        description={content?.description}
        buttonText={content?.buttonText || t('view')}
        icon={<ChainLink2Icon className="size-fluid-4" />}
        w={w}
        h={h}
        y={y}
        buttonIcon={
          openInNewTab ? (
            <ArrowUpRightIcon className="ml-fluid-2 size-fluid-4 shrink-0" />
          ) : (
            <ArrowRightIcon className="ml-fluid-2 size-fluid-4 shrink-0" />
          )
        }
        scaleFactor={scaleFactor}
      />
    </a>
  )
}
