import { AffiliateBlock } from '../affiliate/block'
import { AppleMusicEmbedBlock } from '../apple-music-embed/block'
import { BannerBlock } from '../banner/block'
import { BlogPostBlock } from '../blog-post/block'
import { BookingBlock } from '../booking/block'
import { CalendarBlock } from '../calendar/block'
import { CalendlyEmbedBlock } from '../calendly-embed/block'
import { ContactBlock } from '../contact/block'
import { EducationBlock } from '../education/block'
import { FaqBlock } from '../faq/block'
import { FigmaEmbedBlock } from '../figma-embed/block'
import { FileBlock } from '../file/block'
import { FormBlock } from '../form/block'
import { GalleryBlock } from '../gallery/block'
import { IconGroupBlock } from '../icon-group/block'
import { IframeBlock } from '../iframe/block'
import { ImageComparisonBlock } from '../image-comparison/block'
import { InstagramEmbedBlock } from '../instagram-embed/block'
import { LanguagesBlock } from '../languages/block'
import { LinkListBlock } from '../link-list/block'
import { LoomEmbedBlock } from '../loom-embed/block'
import { MapBlock } from '../map/block'
import { OpeningHoursBlock } from '../opening-hours/block'
import { PageBreakBlock } from '../page-break/block'
import { PinterestEmbedBlock } from '../pinterest-embed/block'
import { PollBlock } from '../poll/block'
import { PortfolioBlock } from '../portfolio/block'
import { ProductBlock } from '../product/block'
import { ProjectBlock } from '../project/block'
import { QrBlock } from '../qr/block'
import { QuotationBlock } from '../quotation/block'
import { RatingsBlock } from '../ratings/block'
import { ServiceAreasBlock } from '../service-areas/block'
import { ServicesBlock } from '../services/block'
import { SkillsBlock } from '../skills/block'
import { SocialFeedBlock } from '../social-feed/block'
import { SocialIconGroupBlock } from '../social-icon-group/block'
import { SoundcloudEmbedBlock } from '../soundcloud-embed/block'
import { SpotifyBlock } from '../spotify/block'
import { SubscribeBlock } from '../subscribe/block'
import { TeamBlock } from '../team/block'
import { TestimonialsBlock } from '../testimonials/block'
import { TextBlock } from '../text/block'
import { TikTokEmbedBlock } from '../tiktok-embed/block'
import { TwitchEmbedBlock } from '../twitch-embed/block'
import { VideoBlock } from '../video/block'
import { WeatherBlock } from '../weather/block'
import { WebsiteBlock } from '../website/block'
import { WebsiteEmbedBlock } from '../website-embed/block'
import { WorkHistoryBlock } from '../work-history/block'
import { XEmbedBlock } from '../x-embed/block'
import { BlockKind, BlockMetaType, BlockType } from './types'

export type BlockContext = 'SEARCH' | 'SIZE' | 'FORM' | 'PUBLIC' | 'EDITOR'

export type BlockProps = {
  block: BlockType
  blockMeta?: BlockMetaType
  w: number
  h: number
  x: number
  y: number
  handleClick?: (block: BlockType, name?: string) => void
  siteSlug: string
  domain?: string
  isPreview?: boolean
  context?: BlockContext
  viewportWidth: number
  scaleFactor: number
}

type Props = {
  kind: BlockKind
} & BlockProps

export const BlockSwitch = ({ kind, ...props }: Props) => {
  switch (kind) {
    case 'WEBSITE':
      return <WebsiteBlock {...props} />
    case 'IFRAME':
      return <IframeBlock {...props} />
    case 'OPENING_HOURS':
      return <OpeningHoursBlock {...props} />
    case 'RATINGS':
      return <RatingsBlock {...props} />
    case 'FILE':
      return <FileBlock {...props} />
    case 'SPOTIFY':
      return <SpotifyBlock {...props} />
    case 'TEXT':
      return <TextBlock {...props} />
    case 'GALLERY':
      return <GalleryBlock {...props} />
    case 'MAP':
      return <MapBlock {...props} />
    case 'SUBSCRIBE':
      return <SubscribeBlock {...props} />
    case 'CALENDAR':
      return <CalendarBlock {...props} />
    case 'CONTACT':
      return <ContactBlock {...props} />
    case 'BANNER':
      return <BannerBlock {...props} />
    case 'FAQ':
      return <FaqBlock {...props} />
    case 'POLL':
      return <PollBlock {...props} />
    case 'FORM':
      return <FormBlock {...props} />
    case 'PRODUCT':
      return <ProductBlock {...props} />
    case 'BOOKING':
      return <BookingBlock {...props} />
    case 'SOCIAL_ICON_GROUP':
      return <SocialIconGroupBlock {...props} />
    case 'TESTIMONIALS':
      return <TestimonialsBlock {...props} />
    case 'PAGE_BREAK':
      return <PageBreakBlock {...props} />
    case 'SERVICES':
      return <ServicesBlock {...props} />
    case 'SERVICE_AREAS':
      return <ServiceAreasBlock {...props} />
    case 'BLOG_POST':
      return <BlogPostBlock {...props} />
    case 'TEAM':
      return <TeamBlock {...props} />
    case 'SOCIAL_FEED':
      return <SocialFeedBlock {...props} />
    case 'APPLE_MUSIC_EMBED':
      return <AppleMusicEmbedBlock {...props} />
    case 'SOUNDCLOUD_EMBED':
      return <SoundcloudEmbedBlock {...props} />
    case 'LOOM_EMBED':
      return <LoomEmbedBlock {...props} />
    case 'PINTEREST_EMBED':
      return <PinterestEmbedBlock {...props} />
    case 'CALENDLY_EMBED':
      return <CalendlyEmbedBlock {...props} />
    case 'FIGMA_EMBED':
      return <FigmaEmbedBlock {...props} />
    case 'TIKTOK_EMBED':
      return <TikTokEmbedBlock {...props} />
    case 'INSTAGRAM_EMBED':
      return <InstagramEmbedBlock {...props} />
    case 'TWITCH_EMBED':
      return <TwitchEmbedBlock {...props} />
    case 'X_EMBED':
      return <XEmbedBlock {...props} />
    case 'ICON_GROUP':
      return <IconGroupBlock {...props} />
    case 'QUOTATION':
      return <QuotationBlock {...props} />
    case 'PORTFOLIO':
      return <PortfolioBlock {...props} />
    case 'SKILLS':
      return <SkillsBlock {...props} />
    case 'LANGUAGES':
      return <LanguagesBlock {...props} />
    case 'WORK_HISTORY':
      return <WorkHistoryBlock {...props} />
    case 'EDUCATION':
      return <EducationBlock {...props} />
    case 'IMAGE_COMPARISON':
      return <ImageComparisonBlock {...props} />
    case 'WEATHER':
      return <WeatherBlock {...props} />
    case 'QR':
      return <QrBlock {...props} />
    case 'PROJECT':
      return <ProjectBlock {...props} />
    case 'LINK_LIST':
      return <LinkListBlock {...props} />
    case 'VIDEO':
      return <VideoBlock {...props} />
    case 'WEBSITE_EMBED':
      return <WebsiteEmbedBlock {...props} />
    case 'AFFILIATE':
      return <AffiliateBlock {...props} />
    default:
      return null
  }
}
